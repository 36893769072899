import { createAction } from '@reduxjs/toolkit';
import { authorizedFetch, getItemNames } from '../utils';
import {
  createContextEventUrl,
  recencyFilters,
  getRange,
  elasticMinRangeFromRecencyFilter,
  elasticMaxRangeFromRecencyFilter,
  elasticIntervalFromRecencyFilter,
  getElasticTimeZoneOffset
} from '../reporting-utils';
import * as itemTypes from '../items/item-types';

export const updateName = createAction('updateName');

const createCountContextEventUrl = (range, recency) => {
  const url = createContextEventUrl({
    range,
    recency
  }).replace('_search', '_count');
  return url;
};

export const getActiveFlows = (
  dateRange,
  recencyFilter = recencyFilters.LAST_HOUR,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);

  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'view'
            }
          },
          {
            term: {
              'target.itemType.keyword': 'flow'
            }
          },
          range
        ]
      }
    },
    size: 0,
    aggs: {
      unique_flows: {
        cardinality: {
          field: 'properties.flowId.keyword'
        }
      }
    }
  };

  authorizedFetch(
    createContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      onComplete(response.aggregations.unique_flows.value);
    })
    .catch(e => addError('Error while fetching Active Flows', e.Message));
};

const getRangeQuery = (dateRange, recencyFilter) => {
  let range;

  if (recencyFilter === recencyFilters.CUSTOM) {
    range = {
      range: {
        timeStamp: {
          time_zone: getElasticTimeZoneOffset(),
          ...getRange(dateRange)
        }
      }
    };
  } else {
    range = {
      range: {
        timeStamp: {
          time_zone: getElasticTimeZoneOffset(),
          gte: elasticMinRangeFromRecencyFilter[recencyFilter],
          lte: elasticMaxRangeFromRecencyFilter[recencyFilter]?.lt
        }
      }
    };
  }

  return range;
};

export const getFlowViews = (
  dateRange,
  recencyFilter = recencyFilters.LAST_HOUR,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);

  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'view'
            }
          },
          {
            term: {
              'target.itemType.keyword': 'flow'
            }
          },
          range
        ]
      }
    }
  };

  authorizedFetch(
    createCountContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      onComplete(response.count);
    })
    .catch(e => addError('Error while fetching Flow Views', e.Message));
};

export const getClicks = (
  dateRange,
  recencyFilter = recencyFilters.LAST_HOUR,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);

  const query = {
    query: {
      bool: {
        must: [
          {
            term: {
              eventType: 'click'
            }
          },
          {
            term: {
              'target.itemType.keyword': 'offer'
            }
          },
          range
        ]
      }
    }
  };

  authorizedFetch(
    createCountContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      onComplete(response.count);
    })
    .catch(e => addError('Error while fetching Offer Clicks', e.Message));
};

export const getCompletedSurveys = (
  dateRange,
  recencyFilter,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);
  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'submit'
            }
          },
          {
            term: {
              'target.itemType.keyword': itemTypes.SURVEY
            }
          },
          {
            term: {
              'properties.isSurveyComplete': true
            }
          },
          range
        ]
      }
    }
  };

  authorizedFetch(
    createCountContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      onComplete(response.count);
    })
    .catch(e => addError('Error while fetching Completed Surveys', e.Message));
};

export const getUserRegistrations = (
  dateRange,
  recencyFilter,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);

  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'submit'
            }
          },
          {
            term: {
              'target.itemType': 'form'
            }
          },
          {
            term: {
              'properties.isFullReg': 'true'
            }
          },
          range
        ]
      }
    },
    size: 0,
    aggs: {
      registrations: {
        filter: {
          bool: {
            must_not: {
              term: {
                'properties.isRepeatReg': true
              }
            }
          }
        }
      },
      repeatReg: {
        filter: {
          bool: {
            must: {
              term: {
                'properties.isRepeatReg': true
              }
            }
          }
        }
      }
    }
  };

  authorizedFetch(
    createContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      if (
        response.aggregations.registrations.doc_count === 0 &&
        response.aggregations.repeatReg.doc_count === 0
      ) {
        return [];
      }

      const chartValues = [
        {
          id: 'New',
          label: 'New',
          value: response.aggregations.registrations.doc_count
        },
        {
          id: 'Repeat',
          label: 'Repeat',
          value: response.aggregations.repeatReg.doc_count
        }
      ];

      onComplete(chartValues);
    })
    .catch(e => addError('Error while fetching Registrations', e.Message));
};

const getEventCountFromDayBucket = (dayBucket, eventType) => {
  const eventBucket = dayBucket.event_type.buckets.find(
    b => b.key === eventType
  );
  if (eventBucket) {
    return eventBucket.doc_count;
  }
  return 0;
};

export const getOfferEventsByType = (
  dateRange,
  recencyFilter = recencyFilters.LAST_12_HOURS,
  onComplete,
  addError
) => {
  let interval;
  if (recencyFilter === recencyFilters.CUSTOM) {
    interval = '1d';
  } else {
    interval = elasticIntervalFromRecencyFilter[recencyFilter];
  }

  const range = getRangeQuery(dateRange, recencyFilter);
  const query = {
    size: '0',
    aggs: {
      offers: {
        filter: {
          bool: {
            must: [
              {
                term: {
                  'target.itemType.keyword': 'offer'
                }
              },
              range
            ]
          }
        },
        aggs: {
          events_per_day: {
            date_histogram: {
              field: 'timeStamp',
              fixed_interval: interval,
              min_doc_count: 0,
              extended_bounds: {
                min: range.range.timeStamp.gte,
                max: range.range.timeStamp.lte || 'now'
              }
            },
            aggs: {
              event_type: {
                terms: {
                  field: 'eventType.keyword'
                }
              }
            }
          }
        }
      }
    }
  };

  authorizedFetch(
    createContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(res => {
      const viewsSeries = {
        id: 'views',
        data: []
      };
      const clicksSeries = {
        id: 'clicks',
        data: []
      };
      const convertsSeries = {
        id: 'converts',
        data: []
      };
      if (res.aggregations) {
        res.aggregations.offers.events_per_day.buckets.forEach(bucket => {
          viewsSeries.data.push({
            x: bucket.key_as_string,
            y: getEventCountFromDayBucket(bucket, 'view')
          });
          clicksSeries.data.push({
            x: bucket.key_as_string,
            y: getEventCountFromDayBucket(bucket, 'click')
          });
          convertsSeries.data.push({
            x: bucket.key_as_string,
            y: getEventCountFromDayBucket(bucket, 'convert')
          });
        });
      }

      onComplete([viewsSeries, clicksSeries, convertsSeries]);
    })
    .catch(e => addError('Error while fetching Offer Trends', e.Message));
};

export const getTop10Flows = (
  dateRange,
  recencyFilter = recencyFilters.LAST_12_HOURS,
  onComplete,
  addError
) => {
  const range = getRangeQuery(dateRange, recencyFilter);

  const query = {
    query: {
      bool: {
        filter: [
          {
            term: {
              eventType: 'submit'
            }
          },
          {
            term: {
              'target.itemType': 'form'
            }
          },
          {
            term: {
              'properties.isFullReg': 'true'
            }
          },
          range
        ]
      }
    },
    size: 0,
    aggs: {
      flowCounts: {
        terms: {
          field: 'properties.flowId.keyword'
        }
      }
    }
  };

  authorizedFetch(
    createContextEventUrl({ range: dateRange, recency: recencyFilter }),
    'POST',
    query
  )
    .then(response => {
      let elements = response.aggregations.flowCounts.buckets.map(m => {
        return m.key;
      });
      let newElements = [];

      getItemNames(
        elements,
        elementNames => {
          newElements = response.aggregations.flowCounts.buckets.map(m => {
            return {
              id: elementNames[m.key],
              label: elementNames[m.key],
              value: m.doc_count
            };
          });

          onComplete(newElements);
        },
        addError,
        [itemTypes.FLOW]
      );
    })
    .catch(e =>
      addError('Error while fetching Registrations by Flow', e.Message)
    );
};
