import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../items/item.actions';
import { validateEpcOverride } from '../epc-override';
import { GOAL_TYPE_CPC } from './job-campaign.constants';

export const receiveJobCampaign = createAction('receiveJobCampaign');
export const setJobCampaignIsBusy = createAction('setJobCampaignIsBusy');
export const modifyJobCampaign = createAction('modifyJobCampaign');
export const closeJobCampaign = createAction('closeJobCampaign');

const jobCampaignModel = (id, name) => ({
  id,
  name,
  type: itemTypes.JOB_CAMPAIGN,
  flowIds: [],
  shouldAdjustSortingEpc: true,
  capStrategy: 'none',
  goal: GOAL_TYPE_CPC
});

export const getItem = getItemInternal(
  jobCampaignModel,
  receiveJobCampaign,
  setJobCampaignIsBusy
);

export const validateProviderConfig = item => {
  if (!item.provider) {
    return 'No Provider present';
  }

  const { providerDetails } = item;

  switch (item.provider) {
    case 'appcast': {
      if (!providerDetails?.apiKey) {
        return 'Appcast config requires an API Key to be set.';
      }
      break;
    }
    case 'neuvoo': {
      if (!providerDetails.chnl1) {
        return 'Neuvoo config requires chnl1 to be set.';
      }
      if (!providerDetails.publisherId) {
        return 'Neuvoo config requires Token to be set.';
      }
      break;
    }
    case 'talroo': {
      if (!providerDetails?.userId) {
        return 'Talroo config requires a User ID to be set.';
      }

      if (!providerDetails?.password) {
        return 'Talroo config requires a Password to be set.';
      }
      break;
    }
    case 'upward': {
      if (!providerDetails?.contractId) {
        return 'Upward config requires a Contract ID to be set.';
      }
      break;
    }
    case 'clickcast': {
      if (!providerDetails?.apiKey) {
        return 'Clickcast config required an Api Key to be set.';
      }
      break;
    }
    default:
      break; //noop
  }
};

export const saveItem = saveItemInternal(modifyJobCampaign, item => {
  const overrideError = validateEpcOverride(item);
  if (overrideError) return overrideError;

  return validateProviderConfig(item);
});
