import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, DropButton, Text } from 'grommet';
import { CircleQuestion, FormPrevious, User } from 'grommet-icons';
import { debounce, startCase } from 'lodash';
import { Link, NavLink, useLocation } from 'react-router-dom';

import Search from '../components/search';
import DraftsPane from '../drafts/draftspane';
import { searchItems } from '../items/items.actions';
import * as itemTypes from '../items/item-types';
import { getKeycloak } from '../auth';
import { buildPathForitem } from '../routing-utils';

const findFieldInArray = (ary, searchString) => {
  for (let i = 0; i < ary.length; i++) {
    if (typeof ary[i] === 'object') {
      if (Array.isArray(ary[i])) {
        let fieldInArray = findFieldInArray(ary[i], searchString);
        if (typeof fieldInArray !== 'undefined') {
          return fieldInArray;
        }
      }
      let fieldWithMatch = findFieldWithMatch(ary[i], searchString);
      if (fieldWithMatch) {
        return fieldWithMatch;
      }
    } else if (
      ary[i]
        .toString()
        .toLowerCase()
        .indexOf(searchString) !== -1
    ) {
      return i;
    }
  }
};

const findFieldWithMatch = (item, searchString) => {
  for (let k in item) {
    if (typeof item[k] === 'object') {
      if (Array.isArray(item[k])) {
        let fieldInArray = findFieldInArray(item[k], searchString);
        if (typeof fieldInArray !== 'undefined') {
          //index of a string in array, lets return the name of the array the match was in
          if (typeof fieldInArray === 'number') {
            return k;
          } else if (fieldInArray === 'name') {
            if (k === 'adGroups') {
              return 'Ad Group Name';
            } else if (k === 'creatives') {
              return 'Ad Creative Name';
            } else if (k === 'dataDeliveries') {
              return 'Data Delivery Name';
            }
          }
          return fieldInArray;
        }
      } else {
        let fieldWithMatch = findFieldWithMatch(item[k], searchString);
        if (fieldWithMatch) {
          return fieldWithMatch;
        }
      }
    } else if (
      item[k]
        .toString()
        .toLowerCase()
        .indexOf(searchString) !== -1
    ) {
      return k;
    }
  }
};

const fieldsToDisplayName = {
  surveyJson: 'survey question',
  categories: 'tags'
};

const getNiceDisplayName = fieldName => {
  return startCase(fieldsToDisplayName[fieldName] || fieldName);
};

const TopNavigation = () => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const location = useLocation();

  const splitPath = location.pathname.split('/');

  const backPath = splitPath.slice(0, 2).join('/');

  const getCurrentItem = () => {
    const itemName = itemTypes.itemTypeNames[splitPath[1]];
    const singularNames = ['Audience Priority', 'Jobs Browser'];

    if (itemName) {
      return (
        `${itemName}${singularNames.includes(itemName) ? '' : 's'}` ?? undefined
      );
    }
  };

  const getItems = useCallback(
    debounce(searchValue => {
      if (!searchValue) {
        setSuggestions([]);
      } else {
        searchItems(searchValue).then(response => {
          const itemsWithMappedTypes = response.hits.hits.filter(h => {
            const readModel = h._index.replace('eventflow-', '');
            return itemTypes.itemTypesByReadModel[readModel];
          });
          setSuggestions(
            itemsWithMappedTypes.map(h => {
              const readModel = h._index.replace('eventflow-', '');
              const item = {
                itemType: itemTypes.itemTypesByReadModel[readModel],
                ...h._source
              };
              return {
                label: <SearchItem item={item} searchValue={searchValue} />,
                value: item
              };
            })
          );
        });
      }
    }, 250),
    []
  );

  useEffect(() => {
    getItems(searchValue);
  }, [getItems, searchValue]);

  return (
    <Box
      direction='row'
      justify='between'
      border={{ side: 'bottom', color: 'border500' }}
      pad='small'
    >
      <Box direction='row' gap='medium' align='center' pad={{ left: 'medium' }}>
        <h3 style={{ color: '#0F2433', fontSize: '22px', margin: 0 }}>
          {splitPath.length <= 3 ? (
            getCurrentItem()
          ) : (
            <Link
              to={backPath}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Box align='center' direction='row'>
                <FormPrevious size='medium' />
                All {getCurrentItem()}
              </Box>
            </Link>
          )}
        </h3>
      </Box>
      <Box direction='row' gap='medium' align='center'>
        <Search
          value={searchValue}
          suggestions={suggestions}
          onChange={newValue => {
            setSearchValue(newValue);
          }}
        />
        <Box direction='row' gap='small' align='center'>
          <DraftsPane />

          <DropButton
            icon={<CircleQuestion a11yTitle='Help Center' />}
            plain
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <>
                <Button plain hoverIndicator={true}>
                  <Box width='small' pad='small'>
                    <a
                      href='https://fluentco.atlassian.net/wiki/spaces/M/overview'
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      <Text>Help Center</Text>
                    </a>
                  </Box>
                </Button>
                <Button plain hoverIndicator={true}>
                  <Box width='small' pad='small'>
                    <a
                      href='https://fluentco.atlassian.net/servicedesk/customer/portal/2'
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      <Text>Report an Issue</Text>
                    </a>
                  </Box>
                </Button>
              </>
            }
          />
          <Box
            align='center'
            gap='small'
            direction='row'
            pad={{ right: 'medium' }}
          >
            <DropButton
              icon={<User />}
              plain
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropContent={
                <>
                  <Box pad='small'>
                    <Text weight='bold' color='dark-3'>
                      {`Hello, ${getKeycloak().tokenParsed.name ||
                        getKeycloak().tokenParsed.preferred_username}`}
                    </Text>
                    <Text color='dark-3'>Administrator</Text>
                  </Box>

                  <Button
                    plain
                    hoverIndicator={true}
                    onClick={() => {
                      localStorage.clear();
                      getKeycloak().logout();
                    }}
                  >
                    <Box width='small' pad='small'>
                      <Text>Logout</Text>
                    </Box>
                  </Button>
                </>
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function SearchItem({ item, searchValue }) {
  const href = buildPathForitem(item);

  return (
    <NavLink
      to={href}
      style={{
        color: 'inherit',
        textDecoration: 'inherit'
      }}
    >
      <Box pad='small' direction='row' gap='small' align='center'>
        {itemTypes.itemTypeIcons[item.itemType]}
        <Box>
          <Text title={item.name} truncate>
            {item.name}
          </Text>
          {item.name.toLowerCase().indexOf(searchValue.toLowerCase()) ===
            -1 && (
            <Text size='small' color='dark-5'>
              {`Found in: ${getNiceDisplayName(
                findFieldWithMatch(item, searchValue.toLowerCase())
              )}`}
            </Text>
          )}
        </Box>
      </Box>
    </NavLink>
  );
}

TopNavigation.displayName = 'TopNavigation';

export default TopNavigation;
