import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Previous, Next } from 'grommet-icons';
import useLocalStorageState from 'use-local-storage-state';

import navItems, { isAuthorizedRoute } from './nav-items';
import NavItem from './nav-item';
import logo from '../images/fluent-logo.png';
import logoCropped from '../images/fluent-logo-cropped.png';
import {
  ADVERTISER,
  AUDIENCE,
  AUDIENCE_PRIORITY,
  CAMPAIGN,
  CONTENT_BLOCK,
  DATA_CAMPAIGN,
  DATA_DELIVERY_GROUP,
  FLOW,
  JOB_CAMPAIGN,
  CALL_CAMPAIGN,
  RULE,
  PAGE,
  REPORT,
  JOBS_BROWSER,
  REWARD,
  SURVEY,
  TRAFFIC_SOURCE,
  TRAFFIC_PARTNER,
  USER_ATTRIBUTE,
  CAMPAIGN_PLACEMENT
} from '../items/item-types';
import {
  isApplicable,
  platforms
} from '../environment-config/environment-config';

export const tabNavItems = [
  { navItem: navItems.FLOWS, itemType: FLOW },
  {
    navItem: navItems.PAGES,
    itemType: PAGE,
    childTabs: [{ navItem: navItems.CONTENTS, itemType: CONTENT_BLOCK }]
  },
  {
    navItem: navItems.SURVEYS,
    itemType: SURVEY,
    childTabs: [{ navItem: navItems.USER_ATTRIBUTES, itemType: USER_ATTRIBUTE }]
  },
  { navItem: navItems.REWARDS, itemType: REWARD },
  {
    navItem: navItems.CAMPAIGNS,
    itemType: CAMPAIGN,
    childTabs: [
      { navItem: navItems.PERFORMANCE_CAMPAIGNS, itemType: CAMPAIGN },
      { navItem: navItems.DATA_CAMPAIGNS, itemType: DATA_CAMPAIGN },
      {
        navItem: navItems.DATA_DELIVERY_GROUPS,
        itemType: DATA_DELIVERY_GROUP
      },
      { navItem: navItems.JOB_CAMPAIGNS, itemType: JOB_CAMPAIGN },
      { navItem: navItems.CALL_CAMPAIGNS, itemType: CALL_CAMPAIGN },
      { navItem: navItems.ADVERTISERS, itemType: ADVERTISER },
      {
        navItem: navItems.CAMPAIGN_PLACEMENTS,
        itemType: CAMPAIGN_PLACEMENT
      }
    ]
  },
  {
    platform: 'cdp',
    navItem: navItems.AUDIENCES,
    itemType: AUDIENCE
  },
  {
    platform: 'pxp',
    navItem: navItems.AUDIENCES,
    itemType: AUDIENCE,
    childTabs: [
      {
        navItem: navItems.AUDIENCE_PRIORITIES,
        itemType: AUDIENCE_PRIORITY
      }
    ]
  },
  {
    navItem: navItems.TRAFFIC,
    itemType: TRAFFIC_SOURCE,
    childTabs: [
      {
        navItem: navItems.TRAFFIC_SOURCES,
        itemType: TRAFFIC_SOURCE
      },
      {
        navItem: navItems.TRAFFIC_PARTNERS,
        itemType: TRAFFIC_PARTNER
      }
    ]
  },
  { navItem: navItems.REPORTS, itemType: REPORT },
  { navItem: navItems.JOBS_BROWSER, itemType: JOBS_BROWSER },
  { navItem: navItems.RULES, itemType: RULE }
];

const TabNavigation = () => {
  const [persistedCollapse, setPersistedCollapse] = useLocalStorageState(
    '_minion_navstate',
    {
      defaultValue: undefined
    }
  );
  const [autoCollapsed, setAutoCollapsed] = useState(
    persistedCollapse || false
  );
  const [activeTab, setActiveTab] = useState(useLocation().pathname);
  let path = useLocation().pathname;

  const isCollapsed =
    typeof persistedCollapse === 'undefined'
      ? autoCollapsed
      : persistedCollapse;

  useEffect(() => {
    // get only the base route path since we only care about the active tab, not the full path here
    const mainPath = path
      .split('/')
      .slice(0, 2)
      .join('/');
    setActiveTab(mainPath);
    // handle automatic collapse if the user has no preference
    if (mainPath !== '/' && typeof persistedCollapse === 'undefined') {
      setAutoCollapsed(true);
    } else {
      setAutoCollapsed(false);
    }
  }, [path, persistedCollapse]);

  const handleNavCollapse = () => {
    setPersistedCollapse(!isCollapsed);
  };

  return (
    <Box
      direction='column'
      justify='between'
      background={{ color: '#0E1E44' }}
      style={{
        minWidth: !isCollapsed ? '165px' : '72px',
        maxWidth: !isCollapsed ? '165px' : '72px'
      }}
    >
      <Box overflow={{ vertical: 'auto', horizontal: 'hidden' }}>
        <Box pad='medium' title='Home' justify='center' align='center'>
          <NavLink to='/'>
            <img
              src={!isCollapsed ? logo : logoCropped}
              alt='Logo'
              height='32px'
            />
          </NavLink>
        </Box>
        {isApplicable({ platform: platforms.PXP })
          ? tabNavItems
              .filter(tab => isAuthorizedRoute(tab.navItem))
              .filter(tab => tab.platform !== platforms.CDP)
              .map(({ navItem, childTabs, itemType }, i) => {
                return (
                  <React.Fragment key={i}>
                    <NavItem
                      isCollapsed={isCollapsed}
                      item={navItem}
                      itemType={itemType}
                      isActiveTab={activeTab === navItem.path}
                      childTabs={childTabs}
                    />
                  </React.Fragment>
                );
              })
          : tabNavItems
              .filter(tab => isAuthorizedRoute(tab.navItem))
              .filter(tab => tab.platform !== platforms.PXP)
              .map(({ navItem, childTabs, itemType }, i) => {
                return (
                  <React.Fragment key={i}>
                    <NavItem
                      isCollapsed={isCollapsed}
                      item={navItem}
                      itemType={itemType}
                      isActiveTab={activeTab === navItem.path}
                      childTabs={childTabs}
                    />
                  </React.Fragment>
                );
              })}
      </Box>
      <Button
        margin='small'
        primary
        focusIndicator={false}
        icon={!isCollapsed ? <Previous /> : <Next />}
        onClick={handleNavCollapse}
        alignSelf={!isCollapsed ? 'end' : 'center'}
        pad='medium'
      />
    </Box>
  );
};

TabNavigation.displayName = 'TabNavigation';

export default TabNavigation;
